const isFocusedElementEditable = (): boolean => {
    const { document } = getClientContext()

    const { activeElement, body } = document

    if (!activeElement || (activeElement === body)) {
        return false
    }

    switch (activeElement.tagName) {
        case 'INPUT':
        case 'TEXTAREA':
            return true
    }

    return activeElement.hasAttribute('contenteditable')
}

const isTypedCharValid = (event: KeyboardEvent) => {
    const {
        keyCode,
        metaKey,
        ctrlKey,
        altKey
    } = event

    if (metaKey || ctrlKey || altKey) {
        return false
    }

    // 0...9
    if (keyCode >= 48 && keyCode <= 57) {
        return true
    }

    // A...Z
    if (keyCode >= 65 && keyCode <= 90) {
        return true
    }

    // a...z
    return keyCode >= 97 && keyCode <= 122
}

export const onStartTyping = (callback: (event: KeyboardEvent) => void): void => {
    const { document } = getClientContext()

    const onKeydown = (event: KeyboardEvent): void => {
        if (!isFocusedElementEditable() && isTypedCharValid(event)) {
            callback(event)
        }
    }

    onEvent(document, 'keydown', onKeydown, { passive: true })
}
